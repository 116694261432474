import React from 'react'
import styles from './Homepage.module.css'
import { useNavigate } from 'react-router-dom'

const HomePage: React.FC = () => {
    const navigate = useNavigate()

    const goToLogin = () => {
        navigate('/login')
    }

    const goToRegistration = () => {
        navigate('/register')
    }

    return (
        <div className={styles.main}>
            <div>
                <h1>{`Coming soon!`}</h1>
            </div>

            <div>
                <button onClick={goToLogin}>Go to login</button>
                <button onClick={goToRegistration}>Go to Registration Page</button>
            </div>
        </div>
    )
}

export default HomePage
