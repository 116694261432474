import React from 'react'
import styles from './Login.module.css'

const Login = () => {
    return (
        <div className={styles.main}>
            <div className={styles.loginContainer}>
                <h2 className={styles.formTitle}>Log in with</h2>
                <div className={styles.socialLogin}>
                    <button className={styles.socialButton}>
                        <img src="google.svg" alt="" className={styles.socialIcon} /> Google
                    </button>
                    <button className={styles.socialButton}>
                        <img src="apple.svg" alt="" className={styles.socialIcon} /> Apple
                    </button>
                </div>

                <p className={styles.separator}>
                    <span>or</span>
                </p>

                <form action="#" className={styles.loginForm}>
                    <div className={styles.inputWrapper}>
                        <input type="email" placeholder="Email address" className={styles.inputField} required />
                        <i className="material-symbols-rounded">mail</i>
                    </div>

                    <div className={styles.inputWrapper}>
                        <input type="password" placeholder="Password" className={styles.inputField} required />
                        <i className="material-symbols-rounded">lock</i>
                    </div>

                    <a href="#" className={styles.forgotPasswordLink}>
                        Forgot Password ?
                    </a>

                    <button className={styles.loginButton}>Log In</button>
                </form>

                <p className={styles.signupText}>
                    {`Don't have an account ?`} <a href="#">Signup now</a>
                </p>
            </div>
        </div>
    )
}

export default Login
